.Capitalize {
  text-transform: capitalize;
}

.FlexEnd {
  display: flex;
  justify-content: flex-end;
}

.FlexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.FlexAlignCenter {
  align-items: center;
  display: flex;
}

.FlexRow {
  display: flex;
  flex-direction: row
}

.FlexColumn {
  display: flex;
  flex-direction: column;
}

.Flex1 {
  flex: 1
}
.Flex3 {
  flex: 3
}

.TextAlignCenter {
  text-align: center;
}

.NoPadding {
  padding: 0;
}

.MarginTop20 {
  margin-top: 20px;
}

.MarginLeft10 {
  margin-left: 10px;
}

.MarginRight10 {
  margin-right: 10px;
}

.MarginBottom10 {
  margin-bottom: 10px;
}

.MaxHeight35 {
  max-height: 35px;
}

.MaxHeight50 {
  max-height: 50px;
}

.MaxWidth100 {
  max-width: 100px;
}

.Height10em {
  height: 10em;
}

.PositionAbsolute {
  height: 100%;
  position: absolute;
}

.TableWrap td {
  flex-wrap: wrap;
  max-width: 110px;
}

.Center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

table .w-5 {
  width: 5%;
}

table .w-10 {
  width: 10%;
}

table .w-20 {
  width: 20%;
}

table .w-30 {
  width: 30%;
}

.table-fixed {
  table-layout: fixed;
  width: 100%;
}

.table-fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.NoPadding td {
  padding: 0;
}

.FillParent p {
  width: 100%;
  margin: 0;
}

.VerticalMiddle td {
  vertical-align: middle !important;
  font-size: 13px;
}

.BackgroundWhite {
  background-color: white;
}

.IconGreen {
  color: darkgreen;
}

.IconRed {
  color: tomato;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block !important;
}

.cursor-pointer {
  cursor: pointer;
}

/* CALENDAR */
.fc-today {
  background-color:inherit !important;
  color: #5a6169;
}

.fc-highlight {
  opacity: 0.6;
}

.fc-content {
  font-size: 0.60em;
  text-align: center;
}

.fc-content > .fc-title {
  color: #000000;
  text-transform: uppercase;
}

.fc-bootstrap .fc-header-toolbar .btn-group .btn-primary {
  border: 1px solid #e1e5eb;
  color: #3d5170;
  background: white;
}

.fc-day > .day-done {
  width: 100%;
  display: block;
  text-align: center;
}

/* CALENDAR */
