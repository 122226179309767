.status-icon {
    font-size: 24px !important;
}

.status-icon.ok, .status-icon.true{
    color: #2ecc71;
}

.status-icon.pending{
    color: #3498db;
}

.status-icon.pending{
    color: #f1c40f;
}

.status-icon.blocked, .status-icon.false{
    color: #e74c3c;
}
